<!--:totalRecords="totalRecords"
:lazy="true"
--><!--:lazy="true"-->
<!--@filter="onFilter($event)"-->
<!--:filters="filters"-->
<!--@filter="onFilter($event)"-->
<!--stateStorage="session"
    stateKey="dt-state-demo-session"-->
    <template>
  <DataTable
    :value="listDataService"
    :lazy="true"
    class="p-datatable-responsive"
    selectionMode="single"
    data-key="id"
    @page="onPage($event)"
    :paginator="pagination"
    :totalRecords="totalRecords"
    :rows="paginationRows"
    :loading="loading"
    :scrollable="(scrollHeight !== '' ? true : false) "
    :scrollHeight="scrollHeight"
    :autoLayout="true"
    filterMatchMode="startsWith"
    :first="firstRecordIndex"
    :rowsPerPageOptions="$constants.LIST_OPTION_COMMON.PER_PAGE_OPTIONS"
    :currentPageReportTemplate=" $constants.LIST_OPTION_COMMON.TEXT_TOTAL_ITEM+' {totalRecords}'"
    :paginatorTemplate="$constants.LIST_OPTION_COMMON.PAGINATOR_TEMPLATE"
  >
    <ProgressBar v-if="display_progress_bar == true" :value="value_process_import">
      {{name_process_import}}: {{value_process_import}}%
    </ProgressBar>
    <div class="div_search mb-3">
      <div class="div_date div_search_mobi div_pc">
        <label class="p-col-fixed txt-right text_align label_company">Xem thống kê </label>
        <!-- <Calendar class=" margin input_width border_color Calendar width_mobi" dateFormat="dd/mm/yy" selectionMode="range" placeholder="DD/MM/YYYY - DD/MM/YYYY" id="date" v-model="date" :showIcon="true" /> -->
        <Calendar
            @clear-click="headerOnFilter"
            @date-select="headerOnFilter"
            :manualInput="false" v-model="date" placeholder="DD/MM/YYYY - DD/MM/YYYY" class="w-100" appendTo="body" :showButtonBar="true" selectionMode="range" dateFormat="dd/mm/yy" :showIcon="true"/>
      </div>

      <div class="div_company div_search_mobi div_pc">
        <label class="p-col-fixed txt-right text_align label_company ">Công ty</label>
        <Dropdown id="selectType" @change="headerOnFilter" class="input_text width_mobi " v-model="SelectedCompany" :options="company" optionLabel="text_lable" optionValue="id" placeholder="Chọn" />
      </div>
      <div class="button_import" style=" flex: 1; display: flex; justify-content: end;">
        <Button v-if="this.role.export == true"   @click="show" label="Xuất excel" class="p-button-success button" />
      </div>
    </div>


    <Dialog
        header="Xuất excel"
        :visible.sync="showDeleteDialog"
        :contentStyle="{overflow: 'visible'}"
        :modal="true"
    >
      <div class="div_date margin_botton">
        <label class="p-col-fixed txt-right text_align label_company">Thời gian bảng quyết toán dịch vụ</label><br>
        <Calendar view="month" :yearNavigator="true" yearRange="2000:2050"  style="width: 100%!important;" class=" margin input_width border_color Calendar width_input_popup" dateFormat="mm/yy" placeholder="mm/yy"   id="date" v-model="import_excel.date" :showIcon="true" />
      </div>
      <div class="p-card-field margin_botton">
        <label class="p-col-fixed txt-right label_company">Kính gửi </label>
        <div class="p-col">
          <Textarea style="width: 100%!important;" :autoResize="true"  rows="3"  v-model="import_excel.dear"  type="text" class="p-col-width border_color text-area" placeholder="Nhập " /><!--
          <InputText style="width: 100%!important;" class="p-col-width input_text border_color width_input_popup" maxlength="255" v-model="import_excel.dear"  id="name" type="text"  placeholder="Nhập " />-->
        </div>
      </div>
      <div class="p-card-field width_size margin_botton">
        <label class="p-col-fixed txt-right margin dif label_company" id="label">Chi phí chênh lệch kì trước<span style="color: red">*</span></label>
        <div class="p-inputgroup input-dif">
          <InputNumber style="width: 100%!important;" class="p-col-width input_distance width_dif border_color width_input_popup" type="text"  maxlength="50" v-model="import_excel.cost" id="size" placeholder="Nhập"  />
<!--          <InputText style="width: 100%!important;" class="p-col-width input_distance width_dif border_color width_input_popup" type="text"  maxlength="50" v-model="import_excel.cost" id="size" placeholder="Nhập"  />-->
          <span class="p-inputgroup-addon" id="km">đ</span>
        </div>
      </div>
       <strong></strong>
      <template
          #footer
      >


        <Button
            label="Hủy"
            @click="showDeleteDialog = false"
            icon="pi pi-times"
            class="p-button-warning callback-btn"
        />
        <Button label="Xuất" icon="pi pi-check" class="main-style-button" @click="export_excel()" />
      </template>
    </Dialog>


    <Column
      v-for="c in model.getDisplayFields()"
      :key="c.key"
      :field="getField(c)"
      :header="getLabel(c)"
      :ref="getField(c)"
      :filterMatchMode="getFilterMatch(c)"
      :sortable="getSortable(c)"
      :bodyClass="(c.class ? 'txt-' + c.class : '')"
      :bodyStyle="getHeaderStyle(c)"
      :headerStyle="getHeaderStyle(c)"
      :headerClass="(c.class ? 'txt-' + c.class : '')"
    >
      <template #body="slotProps">
        <!--{{c}}-->
        <div :class="(slotProps.data.type==1 || slotProps.data.type==2 ? 'bold_row' : '')">
        <span class="responsive-label">{{ getLabel(c) }}</span>
        <img
          v-if="getType(c) == 'Image'"
          :src="getData(c, slotProps.data)"
          style="width: 40px; heigth: 40px"
        />
        <div
          v-else-if="c.type == 'Text'"
          v-html="getData(c, slotProps.data)"
          :style="(c.style ? c.style : '')"
        ></div>
        <div
          v-else-if="getType(c) == 'Custom' || getType(c) == 'Icon'"
          v-html="getData(c, slotProps.data)"
          :style="(c.style ? c.style : '')"
        ></div>

        <Checkbox
          v-else-if="getType(c) == 'Checkbox'"
          v-model="slotProps.data[slotProps.column.field]"
          :binary="true"
          @change="onInputChange($event, slotProps.data, c)"
        />
        <div v-else>
          <div v-if="c.custom">
            <Avatar v-if="c.custom == 'image'" :image="getData(c, slotProps.data)" size="xlarge"  />
            <div v-if="c.custom == 'SelectionText'"  v-html="callbackDataSelect( c ,slotProps.data)"></div>
          </div>
          <span v-else v-html="getData(c, slotProps.data)" :class="getCustomClass(c, slotProps.data[c.key], slotProps.data)"></span>
        </div>
        </div>
      </template>
      <template v-if="c.filter" #filter>
        <InputText
          v-if="c.filter.type == 'input'"
          type="text"
          v-model="filters[$CommonList.getFieldFilter(c)]"
          class="p-column-filter"
          :placeholder="getFilterPlaceHolder(c)"
          @keyup="onFilter($event,c.filter.type,c.key)"
        /><!-- :keyup="onFilter(c.filter.type,c.key)"-->
        <!--v-model="filters[getField(c)]"-->
        <Dropdown
          :key="countDropdown"
          v-if="c.filter.type == 'select'"
          v-model="filters[getField(c)]"
          :options="getFilterOptions(c.key)"
          :optionLabel="c.filter.optionLabel"
          :optionValue="c.filter.optionKey"
          :placeholder="getFilterPlaceHolder(c)"
          class="p-column-filter"
          :showClear="true"
          appendTo="body"
          @change="onFilter($event,c.filter.type,c.key)"
        >
          <template #option="slotProps">
            <div class="p-clearfix">
              <span
                :class="getCustomClass(c, slotProps.option[c.filter.optionKey], slotProps.data)"
                >{{ slotProps.option[c.filter.optionLabel] }}</span
              >
            </div>
          </template>
        </Dropdown>
        <Calendar
          v-if="c.filter.type == 'DateRange'"
          :showOnFocus="false"
          :monthNavigator="true"
          :yearNavigator="true"
          yearRange="2000:2030"
          dateFormat="yy-mm-dd"
          id="date_from"
          v-model="filters[getField(c)]"
          class="p-column-filter"
          :showIcon="true"
          selectionMode="range"
          appendTo="body"
          :showClear="true"
          @date-select="select_date($event,getField(c))"
          :manualInput="false"
          />
      </template>
    </Column>
    <ColumnGroup type="footer">
      <Row>
        <Column footer="Tổng chi phí:" :colspan="8" :footerStyle="{'text-align':'left'}"/>
        <Column :footer="total_price_cost" />
      </Row>
    </ColumnGroup>
    <template #empty>
      {{ $t('datatable.empty') }}
    </template>
    <template #loading>
      {{ $t('datatable.loading') }}
    </template>
  </DataTable>
</template>

<script>
import moment from "moment-timezone";
//import DataServices from "@/core/DataServices";
import ApiRepository from "@/core/ApiRepository";
//import gql from "graphql-tag";
import AuthenticationApp from "@/core/AuthenticationApp";
// import DataServices from "@/core/DataServices";
export default {
  props: {
    model: Object,
    models: Object,
    tableData: Array,
    getFilterOptions: Function,
    actions: Object,
    options: Array,
    whereKeyList: Object,
  },
  data() {
    return {
      total_price_cost:0,
      //filters: {},
      name_process_import:'',
      value_process_import:0,
      display_progress_bar :false,
      group_channel: {
        search: '',
      },
      import_excel:{
        date:null,
        dear:'',
        cost:''
      },
      role:{
        export:null
      },
      display:null,
      showDeleteDialog: false,
      list_report_summary:{},
      company:[],
      date: null,
      SelectedCompany:null,
      countDropdown: 0,
      pagination: false, // true cho phép phân trang
      paginationRows: this.$constants.LIST_OPTION_COMMON.PAGINATION_ROWS_DEFAULT, // số dòng trên 1 trang
      scrollHeight: '', // chiều cao của trang danh sách
      loading: true,
      rowsPerPageOptions: [10, 20, 50, 100],
      first: 0,
      totalRecords: 120,
      totalRecords2: 12,
      test: null,
      listDataService:null,
      filters: {},
      filtersCustom: [],
      lazyParams: {},
      page_transaction:null,
      firstRecordIndex:0,
      data_filter_common:{}
    };
  },
  async created() {
    console.log("createdcreatedcreatedcreated")
    // console.log("modelmodelmodelmodelmodelmodelmodelmodelmodel:",this.model)
    // console.log("sajsaskasa",this.dataList())
    // console.log("apiiiii",this.models)
    // console.log(this.tableData);


    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_REPORT_SUMMARY);
    if (arr_role.indexOf(this.$constants.ROLE.VIEW) < 0){
      this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
    }
    this.role.export = (arr_role.indexOf(this.$constants.ROLE.EXPORT) < 0) ? false:true;
    this.display = this.role.export
    console.log("roleexport",this.display)

    this.test = this.tableData;


    //this.listDataService = await this.dataList();
  },
  watch: {
    /*"date"() {
        if (this.date) {
            if (!this.$commonFuction.isEmpty(this.date[1]) && !this.$commonFuction.isEmpty(this.date[0])) {
              this.$set(this.lazyParams.filters, 'date_order', {value: {to: this.date[1], from: this.date[0]}, type_input: 'DateRange'});
            }
        } else {
            this.$delete(this.lazyParams.filters, 'date_order');
        }
        this.onLazyEvent();
    },
    "SelectedCompany"() {
        if (this.SelectedCompany) {
            this.$set(this.lazyParams.filters, 'company_id', {value: this.SelectedCompany, type_input: 'select'});
        } else {
            this.$delete(this.lazyParams.filters, 'company_id');
        }
        this.onLazyEvent();
    }*/
  },
  async mounted () {


    console.log("mountedmountedmounted")

    this.lazyParams = {
      first: 0,
      rows: this.paginationRows,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    //  filtersTmp:this.filters
    };
    if (!this.$commonFuction.isEmpty(this.$route.query)){
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)){
        this.page_transaction = this.$route.query.page_transaction;
        this.lazyParams = (localStorage.getItem(this.page_transaction)) ? JSON.parse(localStorage.getItem(this.page_transaction)) : {};
        console.log("this.lazyParamsthis.lazyParams:"+this.page_transaction,this.lazyParams);
         this.firstRecordIndex = this.lazyParams.first;
        console.log("this.lazyParams:22222222",this.lazyParams);
       /* if (this.lazyParams){
          console.log("this.lazyParams.filters",this.lazyParams.filters);

        }*/
      }
    }
    var whereAttach ={}
    var listDisplayCompany = AuthenticationApp.getListCompany();
    if (listDisplayCompany != null) {
      //order_shipping(where: {order_shipping_items: {order: {company_id: {_in: ""}}}})
      whereAttach.id =  {_in: listDisplayCompany};
    }
    var listData =await this.$CoreService.getCompanyFilter(whereAttach);
    this.company = listData;
    if (this.company.length > 0){
      this.SelectedCompany = this.company[0]["id"]
    }
    console.log('this.SelectedCompanythis.SelectedCompany:',this.SelectedCompany)
    const firstDayOfMonth = moment().startOf('month').format("YYYY-MM-DD");
    const currentDate = moment().format("YYYY-MM-DD");
    this.$set(this, 'date', [new Date(firstDayOfMonth), new Date(currentDate)]);

    this.loading = false
    this.countDropdown++;
    this.onLazyEvent();
  },
  methods: {
    update_process_bar(name,percent) {
      this.display_progress_bar = true
      this.name_process_import = name
      this.value_process_import = percent
    },
    async export_excel(){
      var that = this
      try {
        //var dataRequest = this.import_excel
        var dataRequest = this.data_filter_common
        for (const [key_1, value_1] of Object.entries(this.import_excel)) {
          dataRequest[key_1] = value_1
        }
        const headers = {
          "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
        };
        this.$commonFuction.is_loading(true);
        var item_res = await ApiRepository.post('_api/order/export-aggregate-costs', dataRequest, {headers});
        var key_file  = (item_res["data"]["data"]["key_file"]) ? item_res["data"]["data"]["key_file"]:null
        console.log("item_checkitem_check:",item_res)
        if (item_res){
          var is_process = 0
          var callData  =  window.setInterval(async () => {
            this.$commonFuction.is_loading(false);
            if (is_process == 1){
              return false
            }
            is_process = 1;
           // var item_import  = (response["data"]["data"]) ? response["data"]["data"]:null
            var dataRequestCheck = {
              key_file:key_file,
            }
            console.log("dataRequestCheckdataRequestCheck:",dataRequestCheck)
            try {
              const headers = {
                "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
              };
              var item_res = await ApiRepository.post('_api/order/check-export-excel-status', dataRequestCheck, {headers});
              var item_check  = (item_res["data"]["data"]) ? item_res["data"]["data"]:null
              console.log("item_checkitem_check:",item_check)
              if (item_check){
                var key_process = item_check.process
                var percent = item_check.total
                if (key_process == 'DONE'){
                  var urL_report = item_check.urL_report
                  //window.open(urL_report, '_blank');

                  var dataResponseDownload = await this.$CoreService.callApiDownloadPost('_api/common/read_file_url',{file_url:urL_report},{})
                  var url = URL.createObjectURL(new Blob([dataResponseDownload.data], {
                    type: 'application/vnd.ms-excel'
                  }))
                  const link = document.createElement('a')
                  link.href = url;
                  var file_excel = 'BCTongHop_'+moment().tz("Asia/Bangkok").format('DDMMYYYY')+'_'+moment().tz("Asia/Bangkok").format('HHmmss')+'.xlsx';
                  link.setAttribute('download', file_excel)
                  document.body.appendChild(link)
                  link.click()

                  clearInterval(callData);
                  this.update_process_bar('Thành công',percent);
                  this.display_progress_bar = false
                  alert('Xuất dữ liệu thành công')
                  this.showDeleteDialog = false
                }else if (key_process == 'ERROR'){
                  clearInterval(callData);
                  alert('Vui lòng thử lại')
                  this.display_progress_bar = false
                }
                else if (key_process == 'PENDDING'){
                  //Đang kt dữ liệu
                  console.log("asdasd")
                  this.update_process_bar('Đang kết xuất dữ liệu',percent);
                }
              }
              is_process = 0;
              //
              return true
            } catch (err) {
              that.$toast.add({
                severity: "error",
                summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                life: 3000,
              });
              console.log(err);
              return false;
            }

            /* step++;
             if (step > vm.seconds) {
               step = 0;
               var stop = await vm.checkImportProcessing();
               if (stop) {
                 vm.$store.commit('setLoading', false);
                 clearInterval(callData);
               }
             }*/
          }, 5000);
        }
        is_process = 0;
        //
        return true
      } catch (err) {
        that.$toast.add({
          severity: "error",
          summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
          detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
          life: 3000,
        });
        console.log(err);
        return false;
      }
    },
   async show(){
      if (!this.$commonFuction.isEmpty(this.SelectedCompany)) {
        var where_obj1 = {company_id: {'_eq': this.SelectedCompany}};
        var obj_data1 = await this.$CoreService.getObjectData('content_export_company',where_obj1);
        if (!this.$commonFuction.isEmpty(obj_data1)) {
          //this.import_excel.date = '2024-03-01'
          //this.import_excel.date =  new Date()
          //this.import_excel.date = obj_data1.date_settlement
          this.import_excel.date = (!this.$commonFuction.isEmpty(obj_data1.date_settlement)) ? moment(obj_data1.date_settlement, 'MM/yyyy').toDate():null
          this.import_excel.dear = obj_data1.content
          this.import_excel.cost = obj_data1.cost_difference
        }else {
          this.import_excel.date =null
          this.import_excel.dear = ''
          this.import_excel.cost = ''
        }
      }
      this.showDeleteDialog = true
    },

    Search(){
      console.log("cogoidnko")
      this.onLazyEvent()
    },
    Add(){
      console.log("cogoidnko")
      this.$router.push({path: '/admin/list-group_channel/add'});
    },


    callbackDataSelect(c,data){
      console.log("channel11 company",data.code);
      var text = "";
      switch(c.key) {
        // key la cai name
        case 'active':
          if(data.active == true){
            text = `<i  style="color: green" class="pi pi-check icon"></i>`;
          }else{
            text = `<i style="color: red" class="pi pi-times icon"></i>`;
          }
          break;
        case 'updated_by_id':
          text = data.updated_by_id[0].fullname ? data.updated_by_id[0].fullname : "-";
          break;
        case 'company_id':
          console.log("channelcompany99",data);
          console.log("channelcompany11",data.group_channel_companies.length);
          var list_company =  data.group_channel_companies ? data.group_channel_companies:[]
          for (var j = 0 ; j < list_company.length; j++)
          {
            if(j != list_company.length -1  )
            {
              text  +=  list_company[j].company.code ? list_company[j].company.code + "; "  : "-";
            }
            else {
              text += list_company[j].company.code ? list_company[j].company.code  : "-";
            }

          }
          break;

        case 'channel_id':
          var list_channel =  data.channel.code ? data.channel.code:[]
            text = list_channel
          console.log("sang1111",list_channel.length)
          console.log("sang1111",list_channel)
          break;
        case 'area_type':
          var area_type =  data.area_type ? data.area_type:''
          text = area_type
          break;
        default:
      }
      return text;
    },
    select_date(event,col_name) {
      if (this.filters[col_name][0] !== null && this.filters[col_name][1] !== null) {
        this.$emit("filter_date", {
          data: this.filters[col_name],
          col: col_name
        });
      }
    },
    getLabel(col) {
      if (col.label) return col.label;
      else return col.name;
    },
    getType(col) {
      if (col.display?.list?.mode == "custom") {
        return "Custom";
      }
      switch (col.type) {
        case "String":
        case "Text":
        case "Computed":
          if (col.inputOptions?.mode == "Image") {
            return "Image";
          }
          if (col.inputOptions?.mode == "selection") {
            return "Select";
          }
          if (col.inputOptions?.mode == "currency") {
            return "Currency";
          }
          if(col.inputOptions?.mode == 'icon') {
            return "Icon";
          }
          return "Input";
        case "numeric":
        case "ShadowField":
        case "number":
          if (col.inputOptions?.mode == "currency") {
            return "Currency";
          } else if (col.inputOptions?.mode == "percentage") {
            return "Percentage";
          } else {
            return "Number";
          }
        case "Selection":
          return "Select";
        case "Checkbox":
          return "Checkbox";
        default:
          return col.type;
      }
    },
    cannotEdit(col) {
      if (col.edit && col.edit.edit == false) return true;
      return false;
    },
    onInputChange(value, data, f) {
      if (f.onChange)  f.onChange(data).bind(this);
      this.$emit("onDataChange", data, f);
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    getCustomClass(col, data, rowData) {
      if (col.displayClass) return col.displayClass(data, rowData);
      return "";
    },
    getField(col) {
      return col.key;
    },
    getHeaderStyle(col) {
      var style = "";
      if (col.width) style = "width: " + col.width + "em;";
      else if (col.name === "stt" || col.name === "STT") style = "width: 3em;";
      else if (col.type === "image" || col.type === "Image") style = "width: 6em;";
      return style;
    },
    getData(col, row) {
      //console.log("colcolcol:",col);
      //console.log("rowrowrowrow:",row);
      var value = '';
      if (col.type == "Date" && row[col.key]) {
        value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("DD/MM/yyyy");
      } else if (col.type == "Datetime" && row[col.key]) {
        value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format(" DD/MM/yyyy HH:mm:ss");
      } else if (col.type == "CustomDate" && row[col.key]) {
        value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("HH:mm - DD/MM/yyyy");
      } else if(col.type == "Hour" && row[col.key]){
        value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("HH:mm");
      } else if (col.type == "Selection") {
        var lists = [];
        if (col.options !== undefined) {
          lists = col.options;
        } else if (col.models !== undefined && this.options[col.key] !== undefined) {
          lists = this.options[col.key];
        }
        if (lists && lists.length) {
          var objResult = lists.filter(a => a.value == row[col.key]);
          if (objResult !== undefined && Object.keys(objResult) && Object.entries(objResult).length) {
            value = objResult[Object.keys(objResult)].label;
          }
        }
      } else {
        if (col.type == "Text" && row[col.key] && row[col.key].length > 100) {
          var result = row[col.key].substr(0, row[col.key].lastIndexOf(" ", 100)) + "...";
          value = result;
        }
        if (this.getType(col) == "Currency") {
          if (row[col.key] == null) row[col.key] = 0;
          value = this.formatCurrency(row[col.key]);
        }
        if (this.getType(col) == "Percentage") {
          if (row[col.key] == null) row[col.key] = `0%`;
          value = row[col.key] + "%";
        }
        value = row[col.key];
      }
      if (col.style) value = '<abbr style="' + col.style + '">' + value + '<abbr/>';
      else if (col.options) {
        var filtered = col.options.filter( a => a.value  === row[col.key]);
        if (Object.keys(filtered) && Object.entries(filtered).length && filtered[0].style) {
          value = '<abbr style="' + filtered[0].style + '">' + value + '<abbr/>';
        }
      }
      return value
    },
    getFilterMatch(col) {
      var filterMatch = "startWith";
      if (col.filter && col.filter.match) {
        filterMatch = col.filter.match;
      }
      return filterMatch;
    },
    getFilterPlaceHolder(col) {
      if (col.filter.placeHolder) {
        return col.filter.placeHolder;
      } else if (col.filter.type == "select") {
        return `Chọn `;
      } else if (col.filter.type == "input") {
        return `Nhập`;
      } else {
        return `Lọc`;
      }
    },
    getSortable(col) {
      return col.sortable && col.sortable == true ? true : false;
    },
    getIcon(iconName) {
      return `pi pi-${iconName}`;
    },
    getDisabled(disabled = false) {
      return disabled;
    },
    actionsClicked(key, action, rowData) {
      this.$emit("action-click", {
        originalEvent: event,
        action: action,
        key: key,
        data: rowData,
        lazyParams:this.lazyParams
      });
    },
    getClass(type) {
      switch (type) {
        case "add":
          return "p-button-add";
        case "delete":
          return "p-button-danger";
        case "edit":
          return "p-button-edit";
        case "adddebt":
          return "p-button-edit"
        case "add-Debt":
          return "p-button-add";
        case "editcustom":
          return "p-button-edit"
        case "addcustom":
          return "p-button-add";
      }
    },
    formatCurrency(value) {
      return value.toLocaleString("vi-VN", {
        style: "currency",
        currency: "VND"
      });
    },
   async dataList(lazyParams) {
      console.log('lazyParams');
      console.log('lazyParams:',lazyParams);
     // var offset = lazyParams.first;
     // var limit = lazyParams.rows;
     // console.log("this.model.tableName:",this.model.tableName)
     // var gqLQueryList = DataServices.getList(this.model.tableName);
     // console.log("ggLQueryList")
     // console.log(gqLQueryList)
     var where = {deleted: {_eq: false}};
     var filters = lazyParams.filters;


     if (this.group_channel.search != "" && this.group_channel.search != undefined)
     {
       where.name =  {
         _iregex: this.$commonFuction.search(this.group_channel.search),
       };
     }

     console.log('filters')
     console.log(filters)
     var dataRequest = {
       //order_id: this.model.id,
     };
     console.log("this.datethis.date:",this.date)
     console.log("this.SelectedCompany.date:",this.SelectedCompany)
     if (!this.$commonFuction.isEmpty(this.date)) {
       if (!this.$commonFuction.isEmpty(this.date[1]) && !this.$commonFuction.isEmpty(this.date[0])) {
         dataRequest["date_order"] = {value: {to: this.date[1], from: this.date[0]}, type_input: 'DateRange'}
       }
     }
     if (!this.$commonFuction.isEmpty(this.SelectedCompany)) {
       dataRequest["company_id"] = {value: this.SelectedCompany, type_input: 'select'}
     }
     if (filters){
       for (const [key, value] of Object.entries(filters)) {
         dataRequest[key] = value;//
         if (!this.$commonFuction.isEmpty(value.value)) {
           if (value.type_input == 'input') {
             if (key == 'code_cost'){
               where.code_cost =  {
                 _iregex: this.$commonFuction.search(value.value),
               };
             }
             if (key == 'name_service'){
               where.name_service =  {
                 _iregex: this.$commonFuction.search(value.value),
               };
             }
           }
           else if (value.type_input == 'select') {
             if (key == 'delivery_vehicle') {
               where['delivery_vehicle'] = {_eq: value.value};
             }
             if (key == 'area_type') {
               where['area_type'] = {_eq: value.value};
             }
           }
         }
       }
     }



       const headers = {
          "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
       };
        this.data_filter_common = dataRequest;
       var list_report_summary = await ApiRepository.post('/_api/order/api-aggregate-costs', dataRequest, {headers});
       var STATUS_NEXT_ORDER = (list_report_summary["data"]["data"]) ? list_report_summary["data"]["data"]:{}
       this.list_report_summary = STATUS_NEXT_ORDER
     console.log("where",where)
       console.log("list_report_summary1:",this.list_report_summary)

     return this.list_report_summary
     // var resData = await this.$apollo.mutate({
     //   mutation: gqLQueryList,
     //   variables: {
     //     where_key:where ,
     //     offset:offset,
     //     limit:limit,
     //     orderBy:{updated_at: 'desc'}
     //   }
     // });
     // var listData = resData.data[this.model.tableName];

     // var gqLQueryCount = DataServices.countData(this.model.tableName);
     // var countRes = await this.$apollo.mutate({
     //   mutation: gqLQueryCount,
     //   variables: {
     //     where_key:where
     //   }
     // });
     // var total = countRes.data[this.model.tableName+'_aggregate']['aggregate']['count'];

     // var dataRes = {
     //   listData: listData,
     //   total: total,
     // };

     // return dataRes;
    },
    onLazyEvent() {
      this.lazyParams.filters = this.filtersCustom;
      console.log('this.lazyParams:', this.lazyParams)
      this.loading = true;
      var that = this;
      this.dataList(this.lazyParams).then(data => {
        console.log("datadata:", data)
        // var count = 0;
        if (data.list_data.length < 1) {
          that.listDataService = data.list_data;
          that.totalRecords = data.total;
          that.loading = false;
        }
        this.total_price_cost = data.total_price_cost
        // var stt = this.lazyParams.first;
        for (var i = 0; i < data.list_data.length; i++) {
          // stt++;
          // data.list_data[i].stt = stt;
          if(data.list_data[i].rel_object_group_uses !== undefined && data.list_data[i].rel_object_group_uses !== null){
              data.list_data[i].name_group_filter = data.list_data[i].rel_object_group_uses.group_id;
          }else{
            data.list_data[i].name_group_filter = 0;
          }
        }
        that.listDataService = data.list_data;
        that.totalRecords = data.total;
        that.loading = false;
      });
    },
    onPage(event) {
      console.log("eventeventeventevent",event);
      this.lazyParams = event;
      this.onLazyEvent();
    },
    onFilter(event,type_input,key) {
      //this.loading = true;
      this.tableData = this.test;
      console.log('this.test',this.test);
      console.log('this.test',this.tableData);
      console.log('tableData',this.whereKeyList);
      console.log('filters:',this.filters);
      this.filtersCustom[key] = {
        type_input:type_input,
        value:this.filters[key],
      };
      console.log('this.filtersCustom:',this.filtersCustom);
      this.lazyParams.first = 0;
      this.onLazyEvent()
      /*setTimeout(() => {
        this.customers = this.datasource.slice(event.first, event.first + event.rows);
        this.loading = false;
      }, 500);*/
    },

    headerOnFilter() {
      this.lazyParams.first = 0;
      this.onLazyEvent()
    },
  }
};
</script>
<style lang="scss">
@media screen and (min-width: 1000px) {
  .p-paginator-bottom {
    padding: 0 !important;
  }
  .icon {
    font-size: 1.5rem;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .manager-title {
    font-size: 24px !important;
  }

  //do rong cot
  .txt-width_135 {
    max-width: 135px !important;
  }
  .txt-width_270 {
    max-width: 270px !important;
  }
  //.search {
  //  display: none !important;
  //}
  //.responsive-label {
  //  display: none !important;
  //}
  .div_button_add {
    display: none !important;
  }
  .p-column-filter{
    width: 100%;
  }
  txt-width_135{
    max-width: 135px!important;
  }
  .p-dropdown-label{
    height: 45px!important;
  }
  .input_text{
    width: 372px!important;
    height: 45px!important;
    //padding: 12px!important;
    border: 1px solid #D0D4D9;
  }
  .Calendar{
    width: 372px!important;
    //height: 48px!important;
  }
  .div_company{
    margin: 0px 0px 0px 12px!important;
  }
  .div_date{
    //margin: 0px 0px 12px 0px!important;
  }
  .label_company{
    font-weight: bold!important;
    padding: 0px !important;
  }
  .p-inputtext{
    padding: 10px!important;
  }
  .div_search{
    display: flex!important;
    margin-bottom: 12px!important;
    //justify-content: space-between;
  }
  .p-col{
    padding: 0px!important;
  }
  .margin_botton{
    margin-bottom: 22px!important;
  }
  .p-button-success{
    background: #C40380;
  }
div.p-dialog{
  width: 600px!important;
}
.width_input_popup{
  width: 100%!important;
}
  .div_pc{
    width: 372px!important;
  }
// .p-dropdown-items-wrapper{
//   max-width: 300px!important;
// }

}








// responsive
// list
@media screen and (max-width: 600px) {
  //.layout-wrapper .layout-sidebar{
  //  left: -1000px;
  //}
  .p-component *{
    text-align: right!important;
  }
  .responsive-label{
    display: block!important;
  }
  .responsive-label {
    font-weight: bold!important;
    display: flex!important;
    text-align: left!important;
    margin-right: 50px!important;
    width: 50%!important;


  }
  .p-column-title{
    display: flex!important;
    text-align: left!important;
    margin-right: 50px!important;
    width: 50%!important;
    //padding: 11px!important;

  }
  .common{
    display: flex!important;
    width: 50%!important;
    justify-content: space-between!important;
  }
  .p-button-edit{
    margin-right: 13px!important;
    width: 38px!important;
    height: 32px!important;
  }
  .p-button-danger{
    text-align: right!important;
    width: 38px!important;
    height: 32px!important;
  }
  .action{
    display: flex!important;
  }
  // tim kiem
  .search{
    padding-left: 0px!important;
    display: block!important;
  }
  .input_search{
    width: 100%!important;
    height: 48px!important;
    border: 1px solid #D0D4D9;
  }
  .text_align{
    display: block!important;
    text-align: left!important;
    font-size: 16px!important;
    font-weight: bold;
  }
  // button add
  .div_button_add{
    display: flex!important;
    justify-content: end!important;
    margin-top: 12px!important;
  }
  .button_add{
    width: 38px!important;
    height: 38px!important;
    background-color: #00973D;
    margin-left: 12px!important;
  }
  #search{
    padding:12px!important;
    text-align: left!important;
  }

  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td{
    display: flex !important;
    //align-items: end!important;
    justify-content: space-between!important;
  }



//  ------------------------------------------------
  .div_search{
    display: block!important;
    margin-bottom: 12px!important;
    //justify-content: space-between;
  }
.width_mobi{
  width: 100%!important;
  height: 45px!important;
}
  .p-button-success{
    margin: 12px 0px 0px 0px !important;
    background: #C40380 !important;
    border: 1px solid #C40380!important;
  }
  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td:last-child{
    border: none!important;
  }
  .label_company{
    text-align: left!important;
  }
  .p-col-fixed{
    padding: 0px!important;
    margin-top: 7px!important;
  }
label{
  margin: 0px 0px 7px 0px!important;
}
  .p-dropdown-items-wrapper{
    max-width: 300px!important;
  }

  .p-placeholder{
    display: flex!important;
  }




}





@media screen and (max-width: 950px) {
  //.layout-wrapper .layout-sidebar{
  //  left: -1000px!important;
  //}
  .p-component *{
    text-align: right!important;
  }


  .responsive-label {
    font-weight: bold!important;
    display: flex!important;
    text-align: left!important;
    margin-right: 50px!important;
    width: 50%!important;
  }
  .p-column-title{
    display: flex!important;
    text-align: left!important;
    margin-right: 50px!important;
    width: 50%!important;
    justify-content: space-between!important;
    align-items: center!important;
    //padding: 11px!important;
  }
  .common{
    display: flex!important;
    width: 50%!important;
    justify-content: space-between!important;
  }
  .p-button-edit{
    margin-right: 13px!important;
    width: 38px!important;
    height: 32px!important;
  }
  .p-button-danger{
    text-align: right!important;
    width: 38px!important;
    height: 32px!important;
  }
  .action{
    display: flex!important;
  }


  // tim kiem
  .search{
    display: block!important;
    padding-left: 0px!important;
  }
  .input_search{
    width: 100%!important;
    height: 48px!important;
    border: 1px solid #D0D4D9;
  }
  .text_align{
    display: block!important;
    text-align: left!important;
    font-size: 16px!important;
    font-weight: bold;
  }
  // button add
  .div_button_add{
    display: flex!important;
    justify-content: end!important;
    margin-top: 12px!important;
  }
  .button_add{
    width: 38px!important;
    height: 38px!important;
    background-color: #00973D;
    margin-left: 12px!important;
  }
  #search{
    padding:12px!important;
    text-align: left!important;
  }

  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td{
    display: flex !important;
    //align-items: end!important;
    justify-content: space-between!important;
  }

//  ----------------------------------------------------
  .div_search{
    display: block!important;
    margin-bottom: 12px!important;
  }
  .width_mobi{
    width: 100%!important;
    height: 45px!important;
  }
  .p-button-success{
    margin: 12px 0px 0px 0px !important;
    background: #C40380 !important;
    border: 1px solid #C40380!important;
  }
  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td:last-child{
    border: none!important;
    font-weight: bold!important;
  }
  .label_company{
    text-align: left!important;
  }
  .p-col-fixed{
    padding: 0px!important;
    margin-top: 7px!important;
  }
  label{
    margin: 0px 0px 7px 0px!important;
  }
  .p-dropdown-items-wrapper{
    max-width: 300px!important;
  }
  .p-dropdown-label{
    display: flex!important;
  }
  .p-component{
    text-align: left!important;
  }

}



</style>

<style lang="css">
.bold_row{
  font-weight: bold;
}
@media screen and (min-width: 1000px) {
  .p-button-success {
    background: #C40380 !important;
    height: 45px !important;
    margin: 35px 0px 0px 436px !important;
    border: 1px solid #C40380 !important;
  }

  .p-dialog {
    justify-content: center !important;
  }
}
@media screen and (max-width: 600px) {
  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td.action_border {
    border: 1px solid #EEEEEE !important;
  }
}

@media screen and (max-width: 1000px) {
  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td.action_border {
    border: 1px solid #EEEEEE !important;
  }
}
</style>